
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import EmbedCodeTab from '@/components/share/EmbedCodeTab.vue'
import PermissionTab from '@/components/share/PermissionTab.vue'
import PrivateShareWarning from '@/components/share/PrivateShareWarning.vue'
import QrCodeTab from '@/components/share/QrCodeTab.vue'
import ShareableLink from '@/components/share/ShareableLink.vue'
import { Tenant } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import {
  JigMetaData,
  JigVisibilities,
  TeamJig,
  TenantJigPermissions,
} from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({
  components: {
    'shareable-link': ShareableLink,
    'qr-code-tab': QrCodeTab,
    'embed-code-tab': EmbedCodeTab,
    'permission-tab': PermissionTab,
    'private-share-warning': PrivateShareWarning,
    'prompt-yes-no': PromptYesNo,
  },
})

export default class ShareModal extends Vue {
  @State('myTenant', { namespace: Namespace.App })
  private myTenant!: Tenant
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetaData

  @Action('updateJigMetadata', { namespace: Namespace.Jig })
  public updateJigMetadata: any
  @Action('updateJigVisibility', { namespace: Namespace.Jig })
  public updateJigVisibility: any
  @Action('loadJigMetadata', { namespace: Namespace.Jig })
  public loadJigMetadata: any
  @Action('loadJigPermissions', { namespace: Namespace.Jig })
  public loadJigPermissions: any
  @Action('loadMyTenantUsers', { namespace: Namespace.App })
  public loadMyTenantUsers: any
  @Action('updateJigPermission', { namespace: Namespace.Jig }) public updateJigPermission: any
  @Action('deleteJigPermission', { namespace: Namespace.Jig }) public deleteJigPermission: any

  public $refs!: Vue['$refs'] & {
    [key: string]: any,
    permissionTab: any,
    embedCodeTab: any,
    qrCodeTab: any,
    shareableLink: any,
    shareWarningPrompt: PromptYesNo,
  }

  protected dialogVisible: boolean = false
  private currentShareTab: number = 3
  private shareTabs: Array<any> = [
    {
      id: 'permissionTab',
      title: 'Share',
      component: 'permission-tab',
      isAvailabelForMultiShare: true,
    },
    {
      id: 'embedCodeTab',
      title: 'Embed',
      component: 'embed-code-tab',
      isAvailabelForMultiShare: false,
    },
    {
      id: 'qrCodeTab',
      title: 'QR Code',
      component: 'qr-code-tab',
      isAvailabelForMultiShare: false,
    }
  ]
  private jigItem: TeamJig | any = {}
  private jigItems: Array<TeamJig> | any = []
  private isLoadingConfig: boolean = false
  private myTenantID: number = -1
  private myTenantType: string = 'sharing'
  private isDifferentJig: boolean = false
  private jigSelectionDiffs: boolean = false
  private isAdvancedPermissionTabActive: boolean = false
  private visibilityLink: string = AppConst.JigVisibilities.Link
  private warningPromptKey: string = this.visibilityLink
  private warningPromptContent: any = {
    [AppConst.JigVisibilities.Link]: {
      title: 'Just a reminder',
      copy: 'Anyone with the link will be able to view this Jig. If privacy is a concern consider making it <strong>private</strong> and giving access to specific people.',
      props: {
        requireCtas: true,
        yesBtnMsg: 'Share',
      },
      events: {
        'on-answer-yes': this.onWarningPromptShare
      },
    },
    [AppConst.JigVisibilities.Private]: {
      title: 'Just a reminder',
      copy: 'This Jig is private, only authorized people will be able to view it.',
      props: {
        requireCtas: true,
        yesBtnMsg: 'Share',
      },
      events: {
        'on-answer-yes': this.onWarningPromptShare
      },
    },
    [AppConst.JigVisibilities.Password]: {
      title: 'Just a reminder',
      copy: 'This Jig is private, people outside your team will need the password to view it.',
      props: {
        requireCtas: true,
        yesBtnMsg: 'Share',
      },
      events: {
        'on-answer-yes': this.onWarningPromptShare
      },
    },
    privateWarning: {
      title: 'Upgrade to share your content privately',
      component: {
        name: 'private-share-warning',
        props: {
          eventName: 'SettingsPrivateSharing_UpgradeButtonClicked',
          callout: 'Secure, private, and invite-only Jigs on our Starter plan.',
        },
      },
      props: {
        requireCtas: false,
      },
      events: {},
    },
    passwordWarning: {
      title: 'Upgrade to share your content with a password',
      component: {
        name: 'private-share-warning',
        props: {
          eventName: 'SettingsPasswordSharing_UpgradeButtonClicked',
          callout: 'Share your content with clients or partners using password protection on our Organization plan.',
          cta: 'See pricing',
        },
      },
      props: {
        requireCtas: false,
      },
      events: {},
    },
    failedInvites: {
      title: 'Invitation failed',
      copy: '',
      props: {
        requireCtas: false,
      },
      events: {
        'on-answer-no': this.onWarningPromptClosed,
      },
    },
    passwordRegenerate: {
      icon: 'Icon_Info_filled',
      title: `Generate new password?`,
      copy: `Jig password already exists. Would like to generate a new one?`,
      props: {
        requireCtas: true,
        noBtnMsg: 'Keep existing',
        yesBtnMsg: 'New password',
      },
      events: {},
    },
  }
  private copyPayload: any = {}
  private permissionTabInitPayload: any = null
  private isInitialLoading: boolean = true
  private isDataRefreshRequired: boolean = false
  private updateResults: any = {}
  private infoAlert: any = {
    isActive: false,
    copy: '',
    innerHtml: '',
    cta: {
      isRequired: false,
      isPrimary: false,
      onCtaClick: this.defaultResetBehaviour,
      copy: 'Cancel',
    },
  }

  @Watch('jigMetadata')
  private async onJigMetadata(value: JigMetaData) {
    this.$store.state.app.jigMetadata = value
  }

  @Watch('myTenant')
  private async onMyTenantChanged(value: Tenant) {
    this.myTenantID = value.ID

    await this.$nextTick()
    if (this.$refs.permissionTab && this.$refs.permissionTab[0]) {
      this.$refs.permissionTab[0].updateMyTenantIndex(this.myTenantID)
    }
    await this.loadMyTenantUsers({})

    if (!this.isInitialLoading) {
      this.isDataRefreshRequired = true
    }
  }

  @Watch('dialogVisible')
  private async onDialogVisibleChange(value: boolean) {
    if (!value && this.isAdvancedPermissionTabActive) {
      await this.hideAdvancedPermissionTab()
    }
  }

  private get isMultiJigShare(): boolean {
    return this.jigItems.length > 1
  }

  private get availableTabs(): any[] {
    let tabs: Array<any> = []
    if (this.isMultiJigShare) {
      tabs = this.shareTabs.filter(tab => tab.isAvailabelForMultiShare)
    } else {
      tabs = this.shareTabs
    }

    return tabs
  }

  private get isSelectedJigChanged(): boolean {
    return (this.jigItems.length === 0 && this.isDifferentJig) ||
      (this.jigItems.length > 0 && this.jigSelectionDiffs)
  }

  protected async onModalClose() {
    if (this.isAdvancedPermissionTabActive) {
      await this.hideAdvancedPermissionTab()
    } else {
      if (this.updateResults.isSuccess === false) {
        this.isDataRefreshRequired = true
      }

      this.$emit('on-close', { updateResults: this.updateResults.isSuccess, })

      this.infoAlert.isActive = false
      if (!this.isMultiJigShare) {
        this.$refs.shareableLink.resetSelectField()
      }
      this.dialogVisible = false
      // Reset active tab to tab 1.
      this.currentShareTab = 0
    }
  }

  public async ShowPrompt(items: Array<TeamJig | any>) {
    this.isLoadingConfig = true
    this.dialogVisible = true
    this.isDifferentJig = items.length === 1 && this.jigItem.id !== items[0].id
    this.jigSelectionDiffs = items.length > 1 && (!this.jigItems.length || this.jigItems.find((jig: TeamJig | any) => !items.find(item => item.id === jig.id)))

    if (items.length === 1) {
      this.jigItem = items[0]
      this.jigItems = []
    } else {
      this.jigItem = {}
      this.jigItems = items
    }

    if (this.isSelectedJigChanged || this.isDataRefreshRequired) {
      if (this.$route.name !== 'Jig detail') {
        await this.loadJigMetadata({ jigId: items[0].id })
        await this.loadJigPermissions({
          jigId: items[0].id,
        })
      }

      await this.loadSharePanelContent(items.length > 1)
    } else {
      this.isLoadingConfig = false
    }

    this.isInitialLoading = false
  }

  private defaultResetBehaviour() {
    if (location) {
      location.reload()
    } else if (window.location) {
      window.location.reload()
    }
  }

  private async loadSharePanelContent(skipIndividualPermissionCheck: boolean) {
    this.myTenantID = this.myTenant.ID
    await this.$nextTick()
    this.permissionTabInitPayload = {
      myTenantID: this.myTenantID,
      skipIndividualPermissionCheck,
    }

    if (this.$refs.permissionTab && this.$refs.permissionTab[0]) {
      this.$refs.permissionTab[0].loadJigDetails(this.permissionTabInitPayload)
      this.permissionTabInitPayload = null
      this.isLoadingConfig = false
    }
  }

  private async hideAdvancedPermissionTab() {
    await this.$nextTick()
    this.$refs.permissionTab[0].closeSharePanel()
    this.isAdvancedPermissionTabActive = false
  }

  public HidePrompt() {
    this.dialogVisible = false
  }

  private warningPromptProps() {
    const props = { ...this.warningPromptContent[this.warningPromptKey].props }
    return props
  }

  private warningPromptEvents() {
    const events = { ...this.warningPromptContent[this.warningPromptKey].events }
    return events
  }

  private async onWarningPromptShare() {
    const { deeplink, showSuccessMessage, activity, event } = this.copyPayload
    await this.$refs.shareableLink.copyShareableLink(deeplink, showSuccessMessage, activity, event)
    this.copyPayload = {}
  }

  private onWarningPromptClosed() {
    this.isLoadingConfig = false
  }

  private onCopyShareableLink(payload: any) {
    this.warningPromptKey = payload.visibility
    this.copyPayload = { ...payload.data }
    this.$refs.shareWarningPrompt.ShowPrompt()
  }

  private canEditJig(jig: TeamJig) {
    if (this.isCRUDEmpty(jig)) {
      return false
    }

    return jig.requestUserPermission.CRUD.U == true
  }

  private isCRUDEmpty(jig: TeamJig) {
    return !jig || !jig.requestUserPermission || !jig.requestUserPermission.CRUD || !Object.keys(jig.requestUserPermission.CRUD).length
  }

  private getShareableLinkProps() {
    if (this.$route.name === 'Jig detail') {
      return {
        jig: this.jigMetadata,
      }
    } else {
      return {
        jig: this.jigItem,
      }
    }
  }

  private getComponentProps(componentName: string) {
    const props: any = {}

    switch (componentName) {
      case 'qr-code-tab':
        props.jigId = this.jigItem.id
        break
      case 'embed-code-tab':
        props.jigData = {
          jigId: this.jigItem.id,
          jigName: this.jigItem.name,
          jigDeeplink: this.jigItem.deeplinkURL,
        }
        props.tierType = this.myTenant.Subscription ? this.myTenant.Subscription.TierType : ''
        break
      case 'permission-tab':
        props.jigIds = this.jigItems.length ? this.jigItems.map((jig: TeamJig) => jig.id) : [this.jigItem.id]
        props.jigOwnerUid = this.jigItems.length ? this.jigItems[0].uid : this.jigItem.uid
        props.readonlyJig = this.jigItems.length ? false : !this.canEditJig(this.jigItem)
        props.permissionDelete = this.deleteJigPermission
        props.permissionUpdate = this.updateJigPermission
        props.isLoadingConfig = this.isLoadingConfig
        break
      default:
        break
    }

    return props
  }

  private getComponentEvents(componentName: string) {
    const events: any = {}

    switch (componentName) {
      case 'qr-code-tab':
        break
      case 'embed-code-tab':
        break
      case 'permission-tab':
        events['on-updating-jig-visibility'] = this.onUpdatingJigVisibility
        events['on-advanced-permission-triggered'] = this.triggerAdvancedPermissionTab
        events['on-update-jig-share-config'] = this.onUpdateJigShareConfig
        events['on-permission-update-callback'] = this.onPermissionUpdateCallback
        events['on-invite-successful'] = this.onInviteSuccessful
        events['on-invite-failed'] = this.onInviteFailed
        events['on-permission-update-start'] = this.onPermissionUpdate
        events['on-bulk-invite-successful'] = this.onPermissionUpdate
        events['on-editor-update-successful'] = this.onPermissionUpdate
        events['on-delete-permission-successful'] = this.onPermissionUpdate
        events['on-permission-update-error'] = this.onPermissionUpdate
        events['on-permission-tab-mounted'] = this.onPermissionTabMounted
        events['on-permission-tab-updated'] = this.onPermissionTabUpdated
        break
      default:
        break
    }

    return events
  }

  protected async onCopySucceeded(payload: any) {
    this.$emit('on-copy-succeeded', payload)
  }

  private showSUPErrorInfoAlert(copy: string) {
    this.infoAlert.isActive = true
    this.infoAlert.copy = copy
    this.infoAlert.cta.isRequired = true
    this.infoAlert.cta.copy = 'Refresh'
    this.infoAlert.cta.onCtaClick = this.onModalClose
  }

  private async onUpdatingJigVisibility(payload: any) {
    this.isLoadingConfig = true
    let currentPermission: any = {}
    let newTeamPermissions: any | TenantJigPermissions = {}

    if (payload.visibility) {
      this.jigMetadata.ProjectVisibility = payload.visibility
    }

    if (payload.teamSharePermissions) {
      newTeamPermissions = {
        TenantID: this.myTenantID,
        Permissions: payload.teamSharePermissions,
      }

      newTeamPermissions.Type = this.myTenantType

      if (!this.jigMetadata.Tenants) {
        this.jigMetadata.Tenants = []
      }

      currentPermission = this.jigMetadata.Tenants[payload.myTenantIndex] || {}

      // Jig details endpoint returns full list of Team Access permissions (fixed in EN-549), this.myTenantIndex indicates which permission object to be updated. It might be an existing one or assign a new one.
      this.jigMetadata.Tenants[payload.myTenantIndex] = newTeamPermissions

      this.updateResults = await this.updateJigMetadata(this.jigMetadata)
    } else {
      const visibilityPayload: JigVisibilities = {
        jigId: this.jigMetadata.Id,
        visibility: payload.visibility,
        regenPassword: payload.regeneratePassword,
        version: this.jigMetadata.Version,
      }
      this.updateResults = await this.updateJigVisibility(visibilityPayload)
    }

    if (!this.updateResults.isSuccess) {
      // Error has happened, terminate here.
      this.isLoadingConfig = false

      if (this.updateResults.status === AppConst.nonSystemErrors.unprocessableEntity.status) {
        // 409 error is not caused by our service, hence we only show an info alert message instead of showing general API Error modal.
        this.showSUPErrorInfoAlert(AppConst.nonSystemErrors.unprocessableEntity.copy)
      }
      return
    } else {
      this.infoAlert.isActive = false
    }

    if (payload.visibility === AppConst.JigVisibilities.Password) {
      segmentEventTracking('SettingsPasswordSharingEnabled', {
        tenantName: this.myTenant.Name,
        tenantId: this.myTenant.ID,
        jigName: this.jigMetadata.ProjectName,
        jigId: this.jigMetadata.Id,
        visibility: this.jigMetadata.ProjectVisibility,
      })

      if (payload.regeneratePassword) {
        segmentEventTracking('SettingsPasswordRegenerated', {
          tenantName: this.myTenant.Name,
          tenantId: this.myTenant.ID,
          jigName: this.jigMetadata.ProjectName,
          jigId: this.jigMetadata.Id,
          visibility: this.jigMetadata.ProjectVisibility,
        })
      }
    }

    if (typeof payload.callback === 'function') {
      if (payload.teamSharePermissions) {
        payload.callback({ currentPermission, newTeamPermissions })
      } else {
        payload.callback()
      }
    }

    await this.loadJigMetadata({ jigId: this.jigMetadata.Id })

    this.isLoadingConfig = false
  }

  private triggerAdvancedPermissionTab() {
    this.isAdvancedPermissionTabActive = true
  }

  private onUpdateJigShareConfig(payload: any) {
    this.$emit('on-update-jig-share-config', payload)
  }

  private onInviteSuccessful(payload: any) {
    this.$emit('on-invite-successful', payload)
    this.isLoadingConfig = payload.isLoadingConfig
  }

  private onInviteFailed(payload: any) {
    this.warningPromptKey = payload.promptKey
    this.warningPromptContent[this.warningPromptKey].copy = payload.failedInvitesCopy
    this.$refs.shareWarningPrompt.ShowPrompt()
  }

  private async onPermissionUpdate(payload: any) {
    this.isLoadingConfig = payload.isLoadingConfig

    if (payload.jigId) {
      await this.loadJigMetadata({ jigId: payload.jigId })
    }

    if (typeof payload.callback === 'function') {
      payload.callback()
    }

    if (payload.hideAdvancedPermissionTab) {
      await this.hideAdvancedPermissionTab()
    }

    if (payload.closeModal) {
      await this.onModalClose()
    }
  }

  private async onPermissionUpdateCallback(callback: Function) {
    await this.loadJigPermissions({
      jigId: this.jigItem.id,
    })

    if (typeof callback === 'function') {
      callback()
    }
  }

  private async onPermissionTabMounted(payload: any) {
    if (this.isLoadingConfig && this.$refs.permissionTab && this.permissionTabInitPayload) {
      this.$refs.permissionTab[0].loadJigDetails(this.permissionTabInitPayload)
      this.permissionTabInitPayload = null
      this.isLoadingConfig = false
    }
  }

  private async onPermissionTabUpdated(payload: any) {
    if (this.isLoadingConfig && this.$refs.permissionTab && this.permissionTabInitPayload) {
      this.$refs.permissionTab[0].loadJigDetails(this.permissionTabInitPayload)
      this.permissionTabInitPayload = null
      this.isLoadingConfig = false
    }
  }

  private async onPrivateShareWarningTriggered(payload: any) {
    segmentEventTracking(payload.eventName, {
      tenantName: this.myTenant.Name,
      tenantId: this.myTenant.ID,
      jigName: this.jigMetadata.ProjectName,
      jigId: this.jigMetadata.Id,
    })
    this.warningPromptKey = payload.promptKey
    this.$refs.shareWarningPrompt.ShowPrompt()
  }

  private async onPasswordRegenerateTriggered(payload: any) {
    this.warningPromptKey = payload.promptKey
    this.warningPromptContent[this.warningPromptKey].copy = `The current password is <span class="text--password">${this.jigMetadata.Password}</span>. Would like to generate a new one?`
    this.warningPromptContent[this.warningPromptKey].events['on-answer-yes'] = async () => {
      await this.onUpdatingJigVisibility({
        ...payload,
        regeneratePassword: true,
      })
    }
    this.warningPromptContent[this.warningPromptKey].events['on-answer-no'] = async () => {
      await this.onUpdatingJigVisibility({
        ...payload,
        regeneratePassword: false,
      })
      this.onWarningPromptClosed()
    }
    this.$refs.shareWarningPrompt.ShowPrompt()
  }
}
