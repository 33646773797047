
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class PromptYesNoVue extends Vue {
  @Prop({ default: false })
  public noLinkCta!: boolean
  @Prop({ default: '' })
  public noLinkHref!: string
  @Prop({ default: '' })
  public noLinkTarget!: string
  @Prop({ default: 'Ok' })
  public yesBtnMsg!: string
  @Prop({ default: 'Cancel' })
  public noBtnMsg!: string
  @Prop({ default: 'primary' })
  public yesBtnColor!: string
  @Prop({ default: 'cancel' })
  public noBtnColor!: string
  @Prop({ default: '' })
  public yesBtnClass!: string
  @Prop({ default: '' })
  public noBtnClass!: string
  @Prop({ default: false })
  public yesBtnTextStyle!: boolean
  @Prop({ default: true })
  public noBtnTextStyle!: boolean
  @Prop({ default: true })
  public contentValid!: boolean
  @Prop({ default: false })
  public isYesBtnOutlined!: boolean
  @Prop({ default: true })
  public hasDivider!: boolean
  @Prop({ default: false })
  public isCtaCenterAligned!: boolean
  @Prop({ default: true })
  public requireCtas!: boolean

  protected dialogVisible: boolean = false

  public ShowPrompt(item?: any) {
    this.dialogVisible = true
  }

  protected onModalBtnClick(yes: boolean) {
    if (yes) {
      this.$emit('on-answer-yes')
    } else {
      this.$emit('on-answer-no')
    }
    this.dialogVisible = false
  }
}
