
import TenantSwitchField from '@/components/input/TenantSwitchField.vue'
import { Permissions } from '@/security/permissions'
import {
  GetTenantsByUserRequest,
  Tenant,
  TenantLogoImageResponse,
} from '@/store/modules/app/types'
import { Namespace, PermissionActions } from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({
  components: {
    'tenant-switch-field': TenantSwitchField,
  },
})

export default class TenantSwitcher extends Vue {
  @State('loadingModal', { namespace: Namespace.Utils })
  public loadingModal!: boolean
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('myTenantIndex', { namespace: Namespace.App })
  public myTenantIndex!: number
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]

  @Action('setMyTenant', { namespace: Namespace.App })
  public setMyTenant: any
  @Action('setMyTenantLogoByID', { namespace: Namespace.App })
  public setMyTenantLogoByID: any
  @Action('loadMyTenant', { namespace: Namespace.App })
  public loadMyTenant: any
  @Action('loadMyTenants', { namespace: Namespace.App })
  public loadMyTenants: any
  @Action('searchTenants', { namespace: Namespace.App })
  public searchTenants: any
  @Action('updateMyTenantsWithNewTenant', { namespace: Namespace.App })
  public updateMyTenantsWithNewTenant: any

  protected dialogVisible: boolean = false
  public numberRules: any = null
  public isSwitchingTenant: boolean = false
  // As we use v-model to bind our tenants to the list we can't bind against the readonly
  // 'myTenants' prop. So we use a local tenants array instead.
  private tenants: Tenant[] = []

  protected created() {
    this.numberRules = ValidationRules.RequiredNumber
  }

  public async ShowDialog() {
    this.dialogVisible = true

    if (this.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      const reqPayload = {
        UrlParams: {
          all: 'false',
        },
        ModalLoad: true,
      } as GetTenantsByUserRequest
      await this.loadMyTenants(reqPayload)
    }
    this.tenants = this.myTenants
    this.loadMyTenantLogos()
  }

  private userCountLabel(tenant: Tenant) {
    return `Users: ${tenant.UserCount}`
  }

  private async selectTenant(tenant: Tenant) {
    if (tenant == null) {
      return
    }

    await this.setMyTenant({
      TenantId: tenant.ID,
    })

    await this.checkTenantPermissions(tenant.ID)

    this.dialogVisible = false
  }

  private async checkTenantPermissions(tenantId: number) {
    const tenantTokens = this.$auth0.tenants
    const token = TenantHelpers.GetTenantTokenByTenantID(tenantTokens, tenantId)

    if (token !== undefined) {
      if (!Permissions.TokenHasPermissionAction(
        token, Permissions.PermDashboard, PermissionActions.Login)) {
        await this.$router.push('/dashboard-restricted')
      } else if (this.$route.path === '/dashboard-restricted') {
        await this.$router.push('/tenant-users')
      }
    }
  }

  private async loadMyTenantLogos() {
    if (this.tenants === undefined) {
      return
    }

    TenantHelpers.LoadLogos(
      this.tenants,
      (res: TenantLogoImageResponse) => {
        this.setMyTenantLogoByID(res)
      }
    )
  }
}
